export interface Degree {
  school: string,
  degree: string,
  link: string,
  year: number,
}

const degrees = [
  {
    school: 'Université Paris Cité',
    degree: 'M.S. Fundamental and Applied Computer Science',
    link: 'https://u-paris.fr/en',
    year: 2022,
  },
  {
    school: 'Université Paris-Saclay',
    degree: 'Bachelor\'s degree in Software and Systems Design and Engineering',
    link: 'https://www.universite-paris-saclay.fr/en',
    year: 2019,
  },
];

export default degrees;
