import React from 'react';
import Table from './Table';
import data from '../../data/stats/site';

const Site = () => (
  // TODO think about persisting this somewhere
  <>
    <h3>Some stats about my GitHub account</h3>
    <Table data={data} />
  </>
);

export default Site;
