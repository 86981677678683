import React from 'react';
import PropTypes from 'prop-types';

import TableRow from './TableRow';
import { ReactNode } from 'react';

export interface TableItem {
  key: string,
  label: string,
  value: number | number | ReactNode | string,
  link?: string,
  format?: (s?: number | ReactNode | string) => string;
}

const Table = ({ data }: { data: TableItem[] }) => (
  <table>
    <tbody>
      {data.map((pair) => (
        <TableRow
          format={pair.format}
          key={pair.label}
          label={pair.label}
          link={pair.link}
          value={pair.value}
        />
      ))}
    </tbody>
  </table>
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    format: PropTypes.func,
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.number,
      PropTypes.string,
    ]),
  })).isRequired,
};

export default Table;
