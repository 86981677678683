import React, { useEffect, useState } from 'react';
import { Octokit } from 'octokit';

/* Keys match keys returned by the github api. Fields without keys are
 * mostly jokes. To see everything returned by the github api, run:
 curl https://api.github.com/repos/mldangelo/personal-site
 */

export interface Follower {
  login: string;
  id: number;
  node_id: string;
  avatar_url: string;
  gravatar_id: string;
  url: string;
  html_url: string;
  followers_url: string;
  following_url: string;
  gists_url: string;
  starred_url: string;
  subscriptions_url: string;
  organizations_url: string;
  repos_url: string;
  events_url: string;
  received_events_url: string;
  type: string;
  site_admin: boolean;
}


const GitHubFollowersCount = () => {
  const [gitHubFollowersCount, setGitHubFollowersCount] = useState<number>();
  const octokit = new Octokit({
    auth: process.env.GITHUB_PAT,
  });
  useEffect(() => {
    octokit.request('GET /users/DjamelALI/followers', {}).then((result => {
      setGitHubFollowersCount((result.data as object[]).length);
    }));
  });
  return <>{gitHubFollowersCount}</>;
};

const data = [
  {
    label: 'Number of followers on GitHub',
    key: 'gitHubFollowersCount',
    link: 'https://github.com/DjamelALI?tab=followers',
    value: <GitHubFollowersCount />,
  },
];

export default data;
