import React, { useState, useEffect } from 'react';
import { TableItem } from '../../components/Stats/Table';

const Age = () => {
  const [age, setAge] = useState<string>();

  const tick = () => {
    const divisor = 1000 * 60 * 60 * 24 * 365.2421897; // ms in an average year
    const birthTime = new Date('1996-01-01T09:24:00');
    setAge(((new Date().valueOf() - birthTime.valueOf()) / divisor).toFixed(11));
  };

  useEffect(() => {
    const timer = setInterval(() => tick(), 50);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return <>{age}</>;
};

const data: TableItem[] = [
  {
    key: 'age',
    label: 'Current age',
    value: <Age />,
  },
  {
    key: 'countries',
    label: 'Countries visited',
    value: 5,
    link:
      'https://www.google.com/maps/d/u/0/edit?mid=11DRV1HcLsLoC3fvk3qCnUhAY1NMYM5k&usp=sharing',
  },
  {
    key: 'location',
    label: 'Current city',
    value: 'Paris',
    link:
      'https://en.wikipedia.org/wiki/Paris',
  },
];

export default data;
