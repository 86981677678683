export interface Course {
  title: string,
  number: string,
  link: string,
  university: string,
}

const courses = [
  {
    title: 'Mathematical logic',
    number: 'L2S1',
    link: '',
    university: 'USTHB',
  },
  {
    title: 'Probability & Statistics',
    number: 'L2S1',
    link: '',
    university: 'USTHB',
  },
  {
    title: 'Graph Algorithms',
    number: 'L3S2',
    link: 'https://datamove.imag.fr/kimthang.nguyen/Teaching/AlgoGraphes/algo_graphes.html',
    university: 'Paris Saclay',
  },
  {
    title: 'Network Protocols',
    number: 'M1S1',
    link: 'https://master.math.univ-paris-diderot.fr/modules/m1info-protocoles-reseaux/',
    university: 'Paris Cité',
  },
  {
    title: 'Introduction to Artificial Intelligence and Game Theory',
    number: 'M1S1',
    link: 'https://master.math.univ-paris-diderot.fr/modules/m1info-theorie-jeux/',
    university: 'Paris Cité',
  },
  {
    title: 'Mobile Component Programming',
    number: 'M1S1',
    link: 'http://www.informatique.univ-paris-diderot.fr/formations/masters/cours/resume_android',
    university: 'Paris Cité',
  },
  {
    title: 'Advanced object-oriented languages',
    number: 'M1S1',
    link: 'http://yunes.informatique.univ-paris-diderot.fr/accueil/enseignement/langages-a-objets-avances-cours-de-programmation-c',
    university: 'Paris Cité',
  },
  {
    title: 'Algorithms',
    number: 'M1S2',
    link: 'http://www.informatique.univ-paris-diderot.fr/formations/masters/cours/resume_algorithmique',
    university: 'Paris Cité',
  },
  {
    title: 'Advanced software engineering',
    number: 'M1S2',
    link: 'http://www.informatique.univ-paris-diderot.fr/formations/masters/cours/resume_genie_logiciel_avance',
    university: 'Paris Cité',
  },
  {
    title: 'Advanced databases',
    number: 'M1S2',
    link: 'http://master.math.univ-paris-diderot.fr/modules/m1info-bases-donnees/',
    university: 'Paris Cité',
  },
  {
    title: 'Advanced systems programming',
    number: 'M1S2',
    link: 'http://www.informatique.univ-paris-diderot.fr/formations/masters/cours/resume_programmation_systeme_avancee',
    university: 'Paris Cité',
  },
  {
    title: 'Study project (realization of a line-following robot)',
    number: 'M1S2',
    link: 'http://yunes.informatique.univ-paris-diderot.fr/projets-longs/',
    university: 'Paris Cité',
  },
  {
    title: 'Information technology law (cyberlaw)',
    number: 'M1S2',
    link: '',
    university: 'Paris Cité',
  },
  {
    title: 'Database Systems Architecture',
    number: 'M2P1',
    link: 'http://www.informatique.univ-paris-diderot.fr/formations/masters/cours/resume_concepts_avances_bdd',
    university: 'Paris Cité',
  },
  {
    title: 'Data mining and decision support',
    number: 'M2P1',
    link: 'http://fabien.viger.free.fr/ml',
    university: 'Paris Cité',
  },
  {
    title: 'software modeling and specification',
    number: 'M2P1',
    link: 'https://www.irif.fr/~sangnier/enseignement/modspec.html',
    university: 'Paris Cité',
  },
  {
    title: 'Internet services protocols',
    number: 'M2P1',
    link: 'https://www.irif.fr/~jch/enseignement/internet/',
    university: 'Paris Cité',
  },
  {
    title: 'Formal verification methods',
    number: 'M2P1',
    link: 'https://www.irif.fr/~abou/cours-mfverif.html',
    university: 'Paris Cité',
  },
  {
    title: 'System & Network Administration',
    number: 'M2P2',
    link: '',
    university: 'Paris Cité',
  },
  {
    title: 'Distributed Algorithms',
    number: 'M2P2',
    link: '',
    university: 'Paris Cité',
  },
  {
    title: 'Information Systems Architecture',
    number: 'M2P2',
    link: 'http://www.informatique.univ-paris-diderot.fr/formations/masters/cours/resume_architecture_systeme_information',
    university: 'Paris Cité',
  },
  {
    title: 'MacOS-X Interfaces and Tools',
    number: 'M2P2',
    link: 'http://yunes.informatique.univ-paris-diderot.fr/accueil/enseignement/macos',
    university: 'Paris Cité',
  },
  {
    title: 'Algorithmic methods for accessing digital information',
    number: 'M2P2',
    link: '',
    university: 'Paris Cité',
  },
  {
    title: 'Distributed programming',
    number: 'M2P2',
    link: '',
    university: 'Paris Cité',
  },
  {
    title: 'Advanced systems',
    number: 'M2P2',
    link: '',
    university: 'Paris Cité',
  },
];

export default courses;
