export interface Position {
  company: string;
  position: string;
  link: string;
  daterange: string;
  points: string[];
}

const positions: Position[] = [
  {
    company: 'Takima',
    position: 'Trainee - Full-stack developer',
    link: 'https://www.takima.fr',
    daterange: 'April 2022 - September 2022',
    points: [
      'Work in teams implementing agile methodologies (Scrum).',
      'Apply good development practices and be a force of proposal for continuous improvement (craftsmanship, TDD, peer-programming).',
      'Design a new solution for the "Deadlock" platform and implement it (frontend: Google Sheets, backend: N8N workflows, Java API).',
      'Enrich the platform by adding exercises (in French and English), their tests and corrections.',
      'Improve UI/UX on Deadlock-Desktop (frontend).',
      'Write scripts to migrate projects from the internal GitLab instance to groups and projects on gitlab.com (backend).',
      'Allow the recruiter/teacher to schedule the date and time to send out invitation to the coding game (backend and frontend).',
      'Allow the recruiter to track code changes made by the candidate (backend).',
      'Improve code coverage with tests.',
      'Maintain up-to-date technical documentation',
    ],
  },
  // {
  //   company: 'Takima',
  //   position: 'Trainee - Full-stack developer',
  //   link: 'https://www.takima.fr',
  //   daterange: 'April 2022 - June 2022',
  //   points: [
  //     'Learn many Java frameworks/tools (Spring, Hibernate, Mockito).',
  //     'Consolidate our knowledge of relational databases (PostgreSQL).',
  //     'Learn a Frontend framework (React/Angular, I chose React).',
  //     'Learn and become familiar with the best practices of the Agile Scrum method.',
  //     'Present the e-commerce site after having finished all the chapters of the training (which are : Java, Maven, REST, Spring, Hibernate, PostgreSQL, Flyway, Pagination; Typescript, React; Docker, Git, GitLab CI/CD, TDD)',
  //   ],
  // },
  {
    company: 'IBISC Research Laboratory',
    position: 'Intern-Machine Learning Engineer',
    link: 'https://www.ibisc.univ-evry.fr/en/',
    daterange: 'April 2019 - Juin 2019',
    points: [
      'Read scientific articles on Deep Learning and Logical Reasoning.',
      'Understanding Deep Learning and Logical Reasoning from Data and Knowledge.',
      'Read the documentation of the "LTN (Logic Tensor Networks)" library and understand how it can be used later in the "Smokers Friends Cancer" example.',
      'Implement the "Smokers Friends Cancer" example which is a classic link prediction problem in "statistical relational learning a.k.a. probabilistic inductive logic programming", where the LTN learns to integrate individuals based on fuzzy ground truths and first-order constraints.',
    ],
  },
  {
    company: 'ITS (Info Tools Solutions)',
    position: 'WinDev Intern-Developer',
    link: 'https://www.infotoolssolutions.com/',
    daterange: 'July 2017 - August 2017',
    points: [
      'Context: In charge of creating/maintaining websites and mobile and/or web applications.',
      'Learning of the WLanguage programming language, the basics of Java and SQL.',
      'Development and maintenance of various applications under Windev and Android Studio.',
    ],
  },
];

export default positions;
