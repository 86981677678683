import React from 'react';

const CategoryButton = ({ handleClick, active, label }: { handleClick: (label: string) => void, active: boolean, label: string }) => (
  <button
    className={`skillbutton ${active ? 'skillbutton-active' : ''}`}
    type="button"
    onClick={() => handleClick(label)}
  >
    {label}
  </button>
);

export default CategoryButton;
