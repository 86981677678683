export interface Route {
  index?: boolean;
  label: string;
  path: string;
}

const routes : Route[] = [
  {
    index: true,
    label: 'Djamel ALI',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  // todo: add Projects section
  // {
  //   label: 'Projects',
  //   path: '/projects',
  // },
  {
    label: 'Stats',
    path: '/stats',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
