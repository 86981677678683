// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

import { faGithub, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const data = [
  {
    link: 'https://github.com/DjamelALI',
    label: 'Github',
    icon: faGithub,
  },
  // {
  //   link: 'https://facebook.com/myusername',
  //   label: 'Facebook',
  //   icon: faFacebookF,
  // },
  // {
  //   link: 'https://www.instagram.com/myusername/',
  //   label: 'Instagram',
  //   icon: faInstagram,
  // },
  {
    link: 'https://www.linkedin.com/in/djamel-ali',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  // {
  //   link: 'https://name.co/myusername',
  //   label: 'myusername List',
  //   icon: faMynamelist,
  // },
  {
    link: 'https://twitter.com/ALI_Djamel1',
    label: 'Twitter',
    icon: faTwitter,
  },
  {
    link: 'mailto:contact@djamel.dev',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
