import React, { useState } from 'react';

import CategoryButton from './Skills/CategoryButton';
import SkillBar from './Skills/SkillBar';
import { categories, Category, Skill, skills } from '../../data/resume/skills';



const Skills = (data: { categories: Category[], skills: Skill[] }) => {
  const initValue = new Map<string, boolean>();
  initValue.set('All', true);
  data.categories.map((cat) => cat.name).forEach(e => initValue.set(e, false));

  const [buttons, setButtons] = useState<Map<string, boolean>>(initValue);

  const handleChildClick = (label: string) => {
    const button = new Map<string, boolean>();
    buttons.forEach((value, key) => button.set(key, value));
    if (button.get(label)) {
      if (!button.get('All')) {
        button.set('All', true);
        button.set(label, false);
      }
    } else {
      button.forEach((_, key) => button.set(key, key === label));
    }
    setButtons(button);
  };

  return (
    <>
      <div className="skills">
        <div className="link-to" id="skills" />
        <div className="title">
          <h3>Skills</h3>
          <p>Here is an overview of my skills.</p>
        </div>
        <div className="skill-button-container">
          {
            [...buttons].map(([key, value]) => (
              <CategoryButton
                label={key}
                key={key}
                active={value}
                handleClick={handleChildClick}
              />
            ))
          }
        </div>
        <div className="skill-row-container">
          {getRows(buttons, categories, skills)}
        </div>
      </div>
    </>
  );
};



function getRows(buttons: Map<string, boolean>, categories: Category[], skills: Skill[]): React.ReactNode {
  // search for true active categories
  let cat = 'All';
  for (const [key, value] of buttons) {
    if (value) {
      cat = key;
    }
  }

  return skills.sort((a, b) => {
    let ret = 0;
    if (a.competency > b.competency) ret = -1;
    else if (a.competency < b.competency) ret = 1;
    else if (a.category[0] > b.category[0]) ret = -1;
    else if (a.category[0] < b.category[0]) ret = 1;
    else if (a.title > b.title) ret = 1;
    else if (a.title < b.title) ret = -1;
    return ret;
  }).filter((skill: Skill) => (cat === 'All' || skill.category.includes(cat)))
    .map((skill: Skill) => (
      <SkillBar
        categories={categories}
        data={skill}
        key={skill.title}
      />
    ));
}



export default Skills;
